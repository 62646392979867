<template>

    <div class="modal-backdrop fade" :class="{ show: showModal }" v-if="hasBackdrop && showModal"></div>
    <div class="modal fade" :class="[{ show: showModal, modalClasses }, this.class]" tabindex="-1" :style="{ display: showModal ? 'block' : 'none' }">
        <div class="modal-dialog">
            <div class="modal-content">
                <slot name="header">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ title }}</h5>
                        <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
                    </div>
                </slot>
                <div class="modal-body">
                    <slot name="body"></slot>
                </div>
                <!--
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
                    <button type="button" class="btn btn-primary" @click="saveChanges" v-if="hasSaveListener">Save changes</button>
                </div>
                -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalComponent',
    props: {
        showModal: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            default: 'Modal title'
        },
        hasBackdrop: {
            type: Boolean,
            default: false
        },
        class: {
            type: String,
            default: ''
        }
    },
    computed: {
        hasSaveListener() {
            return this.$attrs.onSave !== undefined;
        }
    },
    methods: {
        closeModal() {
            this.$emit('update:showModal', false);
        },
        saveChanges() {
            this.$emit('save');
        }
    }
}
</script>

<style scoped>
.modal.fade {
    transition: opacity 0.15s linear;
}
.modal.show {
    opacity: 1;
}
</style>
